<template>
  <div>
    <div class="shared-message container" style="max-width: 800px">
      <span v-if="about && about.u">
        <div class="notificationNO is-info is-warning small" style="text-align: center; opacity: 0.5">
          <span class="icon">
            <i class="fal fa-envelope" aria-hidden="true"></i>
          </span>

          Email shared by
          <router-link :to="'/u/' + about.u" style="font-weight: bold">@{{ about.u }}</router-link>
          {{ about.name }}
          · {{ timeAgo(about.created) }}
          <span class="icon">
            <i class="fal fa-envelope" aria-hidden="true"></i>
          </span>
        </div>
      </span>

      <MessageDetailsModal :email="msg" v-if="msg" />

      <div class="metass"></div>
    </div>
  </div>
</template>

<script>
//import faker from 'faker'
//import VuePaginate from "vue-paginate";
/*
window.inbox = {};
            window.paginate = {
                total: Math.random() * (54236 - 100) + 3
            }
            for (var i = 0; i <= 10; i++) {
                window.inbox[i] = {
                    from: faker.name.findName(),
                    timestamp: null,
                    subject: faker.lorem.sentence().substring(0, 40),
                    snippet: faker.lorem.lines(),
                    fullMail: window.faker.lorem.paragraphs(faker.random.number(40)),
                    email: faker.internet.email()
                };
            }
*/

//export vue compoenn

import Avatar from "vue-avatar-component";
import MessageDetailsModal from "../components/MessageDetailsModal.vue";
// import elipsis from utils
import { elipsis, timeAgo } from "../common/utils";

// DotDrop
import DotDrop from "../components/DotDrop.vue";
//feedCard
import feedCard from "../components/feedCard.vue";

export default {
  name: "inbox",
  components: {
    //feedCard,
    //DotDrop,
    MessageDetailsModal,
    // VuePaginate
  },
  //props for messages
  data() {
    return {
      msg: null,
      loading: true,
      about: null,
    };
  },
  //load msg from API on load
  mounted() {
    this.getMessage();
  },
  computed: {
    //get current route
    isDetailModalActive() {
      return this.$route.params.messageId;
    },
  },
  methods: {
    msgDblCkick(id) {
      var to = this.getMsgDetailPath(id);
      this.$router.push(to);
    },
    //get file icon
    fileIcon: function (filename) {
      var googleDocs = "https://cdn-icons-png.flaticon.com/512/5968/5968517.png";
      return googleDocs;
      //use a cloud service for icons images
      //https://www.iconfinder.com/iconsets/file-icons
      //https://www.iconfinder.com/iconsets/file-icons-2

      let ext = filename.split(".").pop();
      return "/img/file-icons/" + ext + ".png";
    },
    timeAgo: function (date) {
      return timeAgo(date);
    },
    closeModal: function () {
      //do reouter back previous url
      this.$router.go(-1);
      //this.$router.push({ name: "inbox" });
    },
    elipsis: function (str, len) {
      return elipsis(str, len);
    },
    //get messages from API
    async getMessage() {
      // alert(32466);
      // use messageID from param and ShareKey param to get the message
      window.API.getSharedMsg(this.$route.params.messageId, this.$route.params.shareKey)

        .then((data) => {
          this.msg = data.msg;
          this.about = data.about;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    closeBtn: function (id) {
      alert(id);
      //call API to delete message, flag it as "closed"
      // and collapse the message card
    },

    getMailtoHref(msg, reOrFwd) {
      var from = msg.parsed.from.address; //encodeURIComponent(msg.parsed.headers.from);

      const isRe = reOrFwd != "fw";
      var sub = encodeURIComponent(msg.parsed.headers.subject); //works even with special chars. + "'432423<>!!@321312?%&^*()'
      var prefixSub = isRe ? "Re: " : "Fwd: ";
      sub = prefixSub + sub;
      //  var body = encodeURIComponent(msg.parsed.html);

      const sepFwd = "\n\n---------- Forwarded message ----------\n";
      const sepRe = "\n\n---------- Reply above this line ----------\n";
      var sepBody = isRe ? sepRe : sepFwd;
      var body = encodeURIComponent(sepBody + msg.parsed.text);
      // mailto:SEDN?subject=SUB&body=BODYYY

      //return `mailto:${from}?subject=${sub}`;

      return `mailto:${from}?subject=${sub}&body=${body}}`;
    },

    getMsgDetailPath(id) {
      //depending on current base path, return the path to the message detail route
      // in vue get first parth of the path.
      var topPath = this.$route.path.split("/")[1];

      if (topPath == "all") return "/all/" + id;
      if (topPath == "promos") return "/promos/" + id;
      if (topPath == "updates") return "/updates/" + id;
      if (topPath == "team") return "/team/" + id;
      return "/home/" + id;
    },
    showMessage: function (msg, index) {
      //use vue vars
      var msgCard = document.getElementById("msg-card-" + index);
      var msgPane = document.getElementById("message-pane");
      var msgPreview = document.querySelector(".message-preview .content");
    },
  },
};
</script>

<style>
body {
  background: #f0f2f5;
}
.msg {
  margin-top: 18px;
  border-radius: 12px !important;
}

/*  on mobile, educe padding of .msg  */
@media screen and (max-width: 768px) {
  .msg .card-content {
    padding: 8px 8px !important;
  }
  .msg {
    border-radius: 2px !important;
  }
}

.nav.is-dark {
  background-color: #232b2d;
  color: #f6f7f7;
}
.nav.is-dark .nav-item a,
.nav.is-dark a.nav-item {
  color: #f6f7f7;
}
.nav.is-dark .nav-item a.button.is-default {
  color: #f6f7f7;
  background-color: transparent;
  border-width: 2px;
}
.nav.menu {
  border-bottom: 1px solid #e1e1e1;
}
.nav.menu .nav-item .icon-btn {
  border: 3px solid #b7c6c9;
  border-radius: 90px;
  padding: 5px 7px;
  color: #b7c6c9;
}
.nav.menu .nav-item.is-active .icon-btn {
  color: #2eb398;
  border: 3px solid #2eb398;
}
.nav.menu .nav-item .icon-btn .fa {
  font-size: 20px;
  color: #b7c6c9;
}
.nav.menu .nav-item.is-active .icon-btn .fa {
  color: #2eb398;
}
.aside {
  width: 200px;
  display: block;
}
.messages {
  display: block;
  margin-bottom: 200px;
  border-right: 1px solid #dedede;
  padding: 40px 20px;
}
.message {
  display: block;
  background-color: #fff;
  padding: 40px 20px;
}
.aside .compose {
  height: 95px;
  margin: 0 -10px;
  padding: 25px 30px;
}
.aside .compose .button {
  color: #f6f7f7;
}
.aside .compose .button .compose {
  font-size: 14px;
  font-weight: 700;
}
.aside .main {
  padding: 40px;
  color: #6f7b7e;
}
.aside .title {
  color: #6f7b7e;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.aside .main .item {
  display: block;
  padding: 10px 0;
  color: #6f7b7e;
}
.aside .main .item.active {
  background-color: #f1f1f1;
  margin: 0 -50px;
  padding-left: 50px;
}
.aside .main .item:active,
.aside .main .item:hover {
  background-color: #f2f2f2;
  margin: 0 -50px;
  padding-left: 50px;
}
.aside .main .icon {
  font-size: 19px;
  margin-right: 30px;
  color: #a0a0a0;
}
.aside .main .name {
  font-size: 15px;
  color: #5d5d5d;
  font-weight: 500;
}
.messages .action-buttons {
  padding: 0;
  margin-top: -20px;
}
.message .action-buttons {
  padding: 0;
  margin-top: -5px;
}
.action-buttons .control.is-grouped {
  display: inline-block;
  margin-right: 30px;
}
.action-buttons .control.is-grouped:last-child {
  margin-right: 0;
}
.action-buttons .control.is-grouped .button:first-child {
  border-radius: 5px 0 0 5px;
}
.action-buttons .control.is-grouped .button:last-child {
  border-radius: 0 5px 5px 0;
}
.action-buttons .control.is-grouped .button {
  margin-right: -5px;
  border-radius: 0;
}
.pg {
  display: inline-block;
  top: 10px;
}
.action-buttons .pg .title {
  display: block;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 3px;
  font-size: 12px;
  color: #AAAAA;
}
.action-buttons .pg a {
  font-size: 12px;
  color: #aaaaaa;
  text-decoration: none;
}
.is-grouped .button {
  background-image: linear-gradient(#f8f8f8, #f1f1f1);
}
.is-grouped .button .fa {
  font-size: 15px;
  color: #aaaaaa;
}
.inbox-messages {
  margin-top: 60px;
}
.message-preview {
  margin-top: 60px;
}
.inbox-messages .card {
  width: 100%;
}
.inbox-messages strong {
  color: #5d5d5d;
}
.inbox-messages .msg-check {
  padding: 0 20px;
}
.inbox-messages .msg-subject {
  padding: 10px 0;
  color: #5d5d5d;
}
.inbox-messages .msg-attachment {
  float: right;
}
.inbox-messages .msg-snippet {
  padding: 5px 20px 0px 5px;
}
.inbox-messages .msg-subject .fa {
  font-size: 14px;
  padding: 3px 0;
}
.inbox-messages .msg-timestamp {
  float: right;
  padding: 0 20px;
  color: #5d5d5d;
}
.message-preview .avatar {
  display: inline-block;
}
.message-preview .top .address {
  display: inline-block;
  padding: 0 20px;
}
.avatar img {
  width: 40px;
  border-radius: 50px;
  border: 2px solid #999;
  padding: 2px;
}
.address .name {
  font-size: 16px;
  font-weight: bold;
}
.address .email {
  font-weight: bold;
  color: #b6c7d1;
}
.card.active {
  background-color: #f5f5f5;
}
</style>
